/*********************  Default-CSS  *********************/

input[type="file"]::-webkit-file-upload-button {
  cursor: pointer;
}

input[type="file"]::-moz-file-upload-button {
  cursor: pointer;
}

input[type="file"]::-ms-file-upload-button {
  cursor: pointer;
}

input[type="file"]::-o-file-upload-button {
  cursor: pointer;
}

input[type="file"],
a[href],
input[type="submit"],
input[type="button"],
input[type="image"],
label[for],
select,
button,
.pointer {
  cursor: pointer;
}

::-moz-focus-inner {
  border: 0px solid transparent;
}

::-webkit-focus-inner {
  border: 0px solid transparent;
}

*::-moz-selection {
  color: #fff;
  background: #000;
}

*::-webkit-selection {
  color: #fff;
  background: #000;
}

*::-webkit-input-placeholder {
  color: #002039;
  opacity: 1;
}

*:-moz-placeholder {
  color: #002039;
  opacity: 1;
}

*::-moz-placeholder {
  color: #002039;
  opacity: 1;
}

*:-ms-input-placeholder {
  color: #002039;
  opacity: 1;
}

html body {
  // font-family: "Markazi Text";
  font-family: "Segoe UI";
  margin: 0;
  line-height: 1.3;
  background-color: #FAFAFA;
}

html[dir="rtl"] body {
  font-family: "Cairo", sans-serif !important;
}

a,
div a:hover,
div a:active,
div a:focus,
button {
  text-decoration: none;
  -webkit-transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
  -ms-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}

a,
span,
div a:hover,
div a:active,
button {
  text-decoration: none;
}

*::after,
*::before,
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}

.no-list li,
.no-list ul,
.no-list ol {
  list-style: inside none none;
}

.no-list ul,
.no-list ol {
  margin: 0;
  padding: 0;
}

a {
  outline: none;
  color: #555;
}

a:hover {
  color: #000;
}

body .clearfix,
body .clear {
  clear: both;
  line-height: 100%;
}

body .clearfix {
  height: auto;
}

* {
  outline: none !important;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

ul:after,
li:after,
.clr:after,
.clearfix:after,
.container:after,
.grve-container:after {
  clear: both;
  display: block;
  content: "";
}

div input,
div select,
div textarea,
div button {
  // font-family: "Markazi Text";
  font-family: "Segoe UI";

  html[dir="rtl"] & {
    font-family: "Cairo", sans-serif;
  }
}

body h1,
body h2,
body h3,
body h4,
body h5,
body h6 {
  // font-family: "Markazi Text";
  font-family: "Segoe UI";

  html[dir="rtl"] & {
    font-family: "Cairo", sans-serif;
  }

  line-height: 120%;
  color: #002039;
  font-weight: bold;
  margin: 0 0 15px;
}

body h1:last-child,
body h2:last-child,
body h3:last-child,
body h4:last-child,
body h5:last-child,
body h6:last-child {
  margin-bottom: 0;
}

div select {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

div select option {
  font-size: 16px;
  color: #002039;
  padding: 2px 5px;
}

img {
  // margin: 0 auto;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}

body p {
  color: #002039;
  // font-family: "Markazi Text", sans-serif;
  font-family: "Segoe UI", sans-serif;

  html[dir="rtl"] & {
    font-family: "Cairo", sans-serif;
  }

  font-size: 15px;
  line-height: 140%;
  margin: 0 0 15px;
  padding: 0;
}

body p:empty {
  margin: 0;
  line-height: 0;
}

body p:last-child {
  margin-bottom: 0;
}

p strong {
  font-weight: bold;
}

.a-left {
  text-align: left;
}

.a-right {
  text-align: right;
}

.a-center {
  text-align: center;
}

label em {
  color: #ff0000;
  display: inline-block;
  font-style: normal;
  vertical-align: top;
  margin-left: 5px;
}

.hidden {
  display: none !important;
}

// body .container {
//   width: 100%;
//   max-width: 1366px;
// }

/*********************  scroll Hide  *********************/
html.show-menu {
  overflow: hidden;
}

html.show-menu body {
  overflow: hidden;
  height: 100%;
}

/*********************  Default-CSS close  *********************/

/*********************  Gapping  *********************/
section {
  padding-bottom: 50px;
  padding-top: 27px;
}

/*********************  Gapping close  *********************/

/*********************  Default-CSS  *********************/

/*********************  Common-Css  *******************/
.clr:after,
ul:after,
.clearfix:after,
li:after,
.grve-container:after {
  clear: both;
  display: block;
  content: "";
}

.btn.focus,
.btn:focus {
  box-shadow: none !important;
}

.form-control:focus {
  box-shadow: none !important;
}

.title {
  text-align: center;
  max-width: 1150px;
  margin: 2% auto;
  padding: 0 1rem;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  h2 {
    z-index: 1;
    position: relative;
    font-size: 2em;
    color: #024b74;
    font-weight: 900;
    margin: 0 !important;
    // text-align: center;
    // font-family: "Proxima Nova Rg" !important;
    // bottom: 10px;
  }

  h2::before {
    // content: "";
    position: absolute;
    border-style: solid;
    border-color: transparent #001f39 transparent transparent;
    width: 0;
    height: 0px;
    top: 38%;
    left: 3%;
    border-width: 0.5vw 20vw 0.75vw 1vw;
  }

  h2::after {
    // content: "";
    position: absolute;
    border-style: solid;
    border-color: transparent transparent transparent #001f39;
    width: 0;
    height: 0;
    top: 38%;
    right: 3%;
    border-width: 0.5vw 1vw 0.75vw 20vw;
  }

  p {
    font-size: max(1rem, 1.2vw);
    color: #7f7f7f;
  }
}

// @media (max-width: 1063px) {
//   .title {
//     max-width: 48rem;
//   padding: 0 .5rem;

//   }
// }
@media (min-width: 767px) {
  .title h2 {
    margin-bottom: .5rem !important;

  }
}

.modal-backdrop {
  // z-index: 99999;
}

.modal {
  // z-index: 9999999;
}

.modal-content {
  margin: 0 15px;
}

@media (max-width: 768px) {
  .title h2 {
    font-size: 4vw;
  }
}

@media (min-width: 1350px) {
  .title h2::before {
    border-width: 0.5rem 17rem 0.5rem 1rem;
  }

  .title h2::after {
    border-width: 0.5rem 1rem 0.5rem 17rem;
  }
}