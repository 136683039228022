@import './default.scss';
// @import '~@angular/material/prebuilt-themes/indigo-pink.css';

.w-unset {
  width: unset;
}

.w-fit {
  width: fit-content;
}
a:hover {
  color: #00223b;
}

/* Home */
.btn-blue-border i {
  margin-left: 12px;
}

.view-more-btn {
  margin-top: 15px;

  a {
    display: flex;
    align-items: center;
    width: max-content;
  }
}

.radio_modal-body {
  padding: 20px 30px;

  h5 {
    font-size: 25px;
    font-weight: 500;
    margin-bottom: 25px;
  }

  label {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    input {
      position: absolute;
      top: 0;
      right: 0;
      z-index: -1;
      opacity: 0;

      &:checked + span::after {
        opacity: 1;
      }
    }

    span {
      width: 14px;
      height: 14px;
      border: 1px solid #024b74;
      border-radius: 100%;
      margin-left: 7px;
      position: relative;

      &::after {
        content: '';
        background-color: #c53f3f;
        width: 10px;
        height: 10px;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        left: 50%;
        opacity: 0;
        border-radius: 100%;
      }
    }

    p {
      font-size: 20px;
      color: #024b74;
      font-weight: 400;
    }
  }

  .btn-modal {
    margin-top: 30px;
    text-align: end;

    .btn-blue {
      padding: 8px 22px;
      font-size: 20px;
    }
  }
}

.alert_modal-body {
  padding: 30px 0px 0px 0px;
  text-align: center;

  h5 {
    font-size: 28px;
    font-weight: 500;
    margin-bottom: 8px;
  }

  p {
    font-size: 20px;
    font-weight: 500;
  }

  .btn-footer-wrap {
    display: flex;
    align-items: center;
    justify-content: center;

    a {
      width: 50%;
      border: 1px solid #002039;
      padding: 9px;
      font-size: 20px;
      color: #002039;
      border-bottom: 0;
      border-right: 0;

      &:nth-child(2) {
        border-left: none;
      }
    }
  }
}

.time-modal-body {
  padding: 30px;

  h5 {
    text-align: center;
    font-size: 27px;
    font-weight: 400;
    margin-bottom: 30px;
    color: #024b74;
  }

  .input-modal-box {
    max-width: 100%;
    margin: 0 auto;
    margin-bottom: 25px;

    input {
      width: 100%;
      background: #f6f6f6;
      border-radius: 10px;
      width: 100%;
      -webkit-box-shadow: 0 0 0 0.1px #002039;
      box-shadow: 0 0 0 0.1px #002039;
      border: none;
      height: 50px;
      font-size: 18px;
      color: #002039;
      font-weight: 200;
      padding: 15px;
    }
  }

  .btn-modal {
    text-align: end;

    a {
      font-size: 24px;
      width: 130px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: auto;
    }
  }
}

.mobile-profile-wrap {
  display: none !important;
}

.error {
  font-weight: 500;
  color: red;
  font-size: 14px;
}

.success {
  font-weight: 500;
  color: green;
  font-size: 14px;
}

.message-list {
  padding: 0;
  list-style: none;
  margin: 100px 0;
  border-radius: 5px;
  border: 1px solid rgba(1, 30, 46, 0.2);
  max-width: 300px;

  li {
    border-bottom: 1px solid #024b74;
    padding: 10px;

    &:nth-last-child(1) {
      border: none;
    }

    a {
      display: flex;
      align-items: center;

      span {
        width: 50px;
        height: 50px;
        display: block;
        border-radius: 100%;
        margin-left: 11px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      p {
        font-size: 23px;
        color: #011e2e;
        font-weight: 300;
        margin: 0;
      }

      i {
        margin-right: auto;
      }
    }
  }
}
::ng-deep .modal-body {
  img {
    margin: auto;
  }
}

.slidecontainer {
  width: 100%;
}

#myinput,
#myinput2 {
  background: linear-gradient(to right, #fff 0%, #fff 50%, #fff 50%, #fff 100%);
  border-radius: 8px;
  height: 1px;
  width: 88%;
  outline: none;
  transition: background 450ms ease-in;
  -webkit-appearance: none;
  direction: ltr;
}
input[type='range']::-webkit-slider-thumb {
  width: 6px;
  -webkit-appearance: none;
  height: 6px;
  background: #c53f3f;
  border-radius: 100%;
}

html.english {
  .fa-chevron-left:before {
    content: '\f054';
  }

  .view-more-btn a i {
    order: 1;
  }

  #myinput,
  #myinput2 {
    margin-left: auto;
  }
}
// .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle{
//   border-color:#0B7199; 
// }

// .mat-radio-button.mat-accent .mat-radio-inner-circle{
//   color:#0B7199;
//   background-color:#0B7199 ;
// }

// .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
//     background-color:#004070;
// }