@use '@angular/material' as mat;
/* You can add global styles to this file, and also import other style files */
@import "~@ng-select/ng-select/themes/default.theme.css";
// @import './assets/fonts/SegoeUI/stylesheet.css';
// @import './assets/fonts/ProximaNova/stylesheet.css';
@import './assets/scss/style.scss';
@import './assets/scss/responsive.scss';
@import './assets/js/bs-stepper/dist/css/bs-stepper.min.css';

@include mat.core();

$calro-palette: (
  100: #E29E21,
  500: #024B74,
  700: #C63F3F,
  contrast: (100: rgba(black, 0.87),
    500: white,
    700: white,
  )
);

$my-primary: mat.define-palette($calro-palette, 500);
$my-accent: mat.define-palette($calro-palette, 100);
$my-warn: mat.define-palette($calro-palette, 700);

$my-theme: mat.define-light-theme((color: (primary: $my-primary,
        accent: $my-accent,
        warn: $my-warn,
      ),
      typography: mat.define-typography-config(),
      density: 0,
    ));

@include mat.button-theme($my-theme);
@include mat.menu-theme($my-theme);
@include mat.badge-theme($my-theme);
@include mat.tooltip-theme($my-theme);
@include mat.bottom-sheet-theme($my-theme);
// @include mat.dialog-theme($my-theme);
// @include mat.dialog-theme($my-theme);
// @include mat.core-theme($my-theme);

@tailwind base;
@tailwind components;
@tailwind utilities;


$primary : #0A4D76;

:root {
  --primary: #024B74;
  --primary-dark: #002039;
  --secondary: #C63F3F;
  --white: #FCFAFA;
  --gray: #EAEAEA;
}

.text-italic {
  font-style: italic;
}

.certificationModel .modal-content {
  padding: 20px 20px 10px !important;
}

.max-width {
  padding: 0 6%;
  max-width: 1730px;
  margin: auto;

  @media screen and (max-width: 1024px) {
    margin: 0 20px;
    padding: 0;
  }
}

.d-margin {
  margin-top: 126px;
  padding: 50px 0;
}

.bg-section {
  background-color: #e0f2ff !important;
}

body[dir="rtl"] {
  .modal-header .btn-close {
    margin: 0;
  }
}

.btn {
  border-radius: 10px;
}

.claroBtn {
  background-color: #FFFFFF;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  border-radius: 10px;
  color: $primary;
  border: none;
}

.claroBtn.active,
.claroBtn:hover {
  background-color: $primary ;
  color: #FFFFFF;
}

.btn-blue {
  background: #024b74;
  color: #fff;
  border-radius: 10px;
  transition: all 0.5s;

  &:hover,
  &.active {
    background: #00243f;
    color: #fff;
  }
}

.btn-blue-border {
  padding: 10px 12px;
  border: 1px solid #024b74;
  font-size: 25px;
  color: #024b74;
  font-weight: 400;

  &:hover,
  &.active {
    background: #024b74;
    color: #fff;
  }
}

.btn-white-border {
  font-size: 25px;
  color: #ffffff;
  font-weight: 400;
  border: 2px solid #fff;
  padding: 5px 5px;
  border-radius: 10px;

  &:hover,
  &.active {
    background: #fff;
  }
}

.btn-red-02 {
  font-size: 25px;
  color: #fff;
  font-weight: 400;
  border: 2px solid #c53f3f;
  padding: 5px 12px;
  border-radius: 10px;
  background: #c53f3f;

  &:hover,
  &.active {
    background: #9b2727;
    color: #fff;
    border-color: #9b2727;
  }
}

.placeholder-color {
  color: #ced4da !important;
}

select {
  option[value=""] {
    color: #ced4da !important;
  }
}

.overflow-anchor-none {
  overflow-anchor: none;
}

.dialog-modal-body {
  padding: 30px;

  h5 {
    text-align: center;
    font-size: 27px;
    font-weight: 400;
    margin-bottom: 30px;
    color: #024b74;
  }
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: unset;
  left: unset;
  width: 20px;
  height: 20px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #000000;
  animation: spinner 0.8s linear infinite;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

.linear-background {
  display: block;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 1000px 104px;
  position: relative;
  overflow: hidden;
}

/*****************************************************
	Custom styling ngx-intl-tel-input
*****************************************************/

.iti {
  display: block !important;
  margin-bottom: 20px;
  direction: ltr;
}

.iti .dropdown-menu.country-dropdown {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-color: #c7cace;
  margin-top: -1px;
}

.iti .iti__country-list {
  box-shadow: none;
  font-size: 14px;
  margin-left: 0;
  width: 244px;
  max-height: 170px;
}

.iti__flag-container.open+input {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.iti .search-container input {
  font-size: 14px;
  border-color: #c7cace;
  border-radius: 0;
  padding: 5px 10px;
}

.iti .search-container input:focus {
  outline: none;
}

@media screen and (max-width: 479px) {
  .iti .iti__country-list {
    width: 88.3vw;
  }
}

ngx-intl-tel-input input {
  height: 44px;
  margin-bottom: 20px;
  padding: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: #c7cace;
  border-radius: 4px;
  font-size: 18px;
}

ngx-intl-tel-input input {
  width: 100% !important;
}

ngx-intl-tel-input.ng-invalid.ng-touched input {
  border: 1px solid #c0392b;
}

ngx-intl-tel-input input:hover {
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.24);
}

ngx-intl-tel-input input:focus {
  outline: none !important;
  border-color: #3498db;
  box-shadow: 0 0 0 0 #000;
}

ngx-intl-tel-input input::-webkit-input-placeholder {
  color: #bac2c7;
}

ngx-intl-tel-input input:-ms-input-placeholder {
  color: #bac2c7;
}

ngx-intl-tel-input input::-ms-input-placeholder {
  color: #bac2c7;
}

ngx-intl-tel-input input::placeholder {
  color: #bac2c7;
}

ngx-intl-tel-input input[disabled] {
  background-color: #e5eaf1;
}

::ng-deep .siq_bR {
  bottom: 60px !important;
}

::ng-deep .zsiq_flt_rel {
  background-color: #064A74 !important;
}

mat-dialog-container {
  background-color: white;
}

.mat-mdc-menu-content,
.mat-mdc-menu-content .mat-mdc-menu-item .mdc-list-item__primary-text {
  font-family: "Cairo", sans-serif !important;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}