@media only screen and (min-width: 1430px) {
  .container {
    max-width: 1290px;
  }
}
@media only screen and (min-width: 1600px) {
  .container {
    max-width: 1440px;
  }
}
@media only screen and (min-width: 1800px) {
  .container {
    max-width: 1630px;
  }
}

@media only screen and (max-width: 1199px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding: 0;
    margin-left: 25px;
  }

  .d-margin {
    margin-top: 106px;
  }

  .btn-blue-border {
    font-size: 22px;
  }

}

@media only screen and (max-width: 991px) {
  .navbar-toggler-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    left: 0;
    height: 2px;
    background: #002039;
    transition: all 0.5s;
  }

  .navbar-toggler {
    padding: 0;
    width: 30px;
    height: 30px;
    position: relative;
    z-index: 9999;
  }

  .navbar-toggler:focus {
    box-shadow: none;
  }

  .navbar-toggler-icon::after {
    content: '';
    position: absolute;
    top: -8px;
    left: 0;
    width: 100%;
    height: 2px;
    background: #002039;
    transition: all 0.5s;
  }

  .navbar-toggler-icon::before {
    content: '';
    position: absolute;
    bottom: -8px;
    left: 0;
    width: 60%;
    height: 2px;
    background: #002039;
    transition: all 0.5s;
  }

  html.show-menu .navbar-toggler-icon {
    background: #0000;
  }

  html.show-menu .navbar-toggler-icon::before {
    width: 100%;
    transform: rotate(-45deg);
    bottom: 0;
  }

  html.show-menu .navbar-toggler-icon::after {
    width: 100%;
    transform: rotate(45deg);
    top: 0;
  }

  #navbarSupportedContent {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 999;
    background: #fff;
    width: 100%;
    padding: 100px 30px 20px;
    overflow: auto;
    text-align: center;
    transform: translateX(-100%);
    transition: all 0.5s;
    display: block !important;
    height: 100vh;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    margin: 10px 0;
  }

  html.show-menu #navbarSupportedContent {
    transform: translateX(0);
    transition: all 0.5s;
  }
  .mobile-profile-wrap {
    display: block !important;
  }
  .desktop-profile-wrap {
    display: none !important;
  }
}

@media only screen and (max-width: 767px) {
  .app-download-btn {
    margin: 0 10px;
  }
}

// @media only screen and (max-width: 479px) {

//   .title h2 {
//     font-size: 28px;
//   }

//   .title {
//     margin-bottom: 30px;
//   }

//   section {
//     padding: 40px 0;
//   }
// }

@media only screen and (max-width: 350px) {
  .post-price-wrap {
    flex-wrap: wrap;
  }
}
